





































import { defineComponent, inject, ref } from '@vue/composition-api';
import { useActions } from 'vuex-composition-helpers';

import { LoginData } from '@/models/auth.model';
import { LOGIN } from '@/store/auth.actions';
import VueRouter from 'vue-router';

export default defineComponent({
  name: 'Login',
  // props: {
  // },
  components: {},
  setup: () => {
    const showPassword = ref(false);
    const username = ref('');
    const password = ref('');

    const actions = useActions([`${LOGIN}`]);
    const loginAction = actions[LOGIN];

    const router = inject<VueRouter>('$router');
    const login = async () => {
      const statusResult = await loginAction({
        user: username.value,
        password: password.value
      } as LoginData);

      if (statusResult === 200) {
        router?.push({ name: 'Home' });
      }
    };

    return {
      showPassword,
      username,
      password,
      login
    };
  }
});
